<template>
  <cv-form class="confirmOrder" @submit.prevent="sendMessage">
    <cv-grid>
      <cv-row>
        <cv-column>
          <h1>Inserisci i tuoi dati</h1>
          <span>Compilare tutti i campi per inviare la prenotazione.</span>
        </cv-column>
        </cv-row>
        <cv-row>
        <cv-column>
          <cv-text-input
            v-model="customer"
            label="Nome attività"
            placeholder="Nome attività"
            @input="checkCustomerName"
            :value="customer"
          >
            <template v-if="customerNameErrorSlot" slot="invalid-message"
              >Inserire la ragione sociale</template
            >
          </cv-text-input>
        </cv-column>
      </cv-row>
      <cv-row>
        <cv-column>
          <cv-text-input
            label="Numero di telefono (se diverso da quello Whatsapp)"
            v-model="customerPhone"
            placeholder="Numero di telefono"
          >
          </cv-text-input>
        </cv-column>
      </cv-row>
        <cv-row>
        <cv-column>  
            <cv-select
            label="Giorno di chiusura settimanale"
            v-model="customerDayOff"
            >
                <cv-select-option disabled hidden>-- Giorno di chiusura --</cv-select-option>
                <cv-select-option value="Lunedì">Lunedì</cv-select-option>
                <cv-select-option value="Martedì">Martedì</cv-select-option>
                <cv-select-option value="Mercoledì">Mercoledì</cv-select-option>
                <cv-select-option value="Giovedì">Giovedì</cv-select-option>
                <cv-select-option value="Venerdì">Venerdì</cv-select-option>
                <cv-select-option value="Sabato">Sabato</cv-select-option>
                <cv-select-option value="Domenica">Domenica</cv-select-option>
            </cv-select>
        </cv-column>
      </cv-row>
      <cv-row>
        <cv-column>
          <p>
            La tua prenotazione verrà inviata tramite Whatsapp.
          </p>
        </cv-column>
      </cv-row>
    </cv-grid>
    <cv-button-set stacked="stacked">
      <router-link to="/checkout" custom v-slot="{ navigate }">
        <cv-button @click="navigate" size="lg" kind="secondary"
          >Indietro</cv-button
        >
      </router-link>
      <cv-button :disabled="order_sent" :icon="SendAltFilled32" size="lg" @click="sendMessage"
        >Invia prenotazione
      </cv-button>
    </cv-button-set>
  </cv-form>
</template>

<script>
import SendAltFilled32 from '@carbon/icons-vue/es/send--alt--filled/32';

export default {
  name: 'ConfirmOrder',
  data() {
    return {
      phoneNumber: '3398838833',
      customerNameErrorSlot: false,
      customer: '',
      status: '',
      customerDayOff: '-- Giorno di chiusura --',
      customerPhone: '',
      SendAltFilled32,
      order_sent: false
    };
  },
  mounted() {
    if (this.$store.state.customer)
        this.customer = this.$store.state.customer;
    if (this.$store.state.customerDayOff)
        this.customerDayOff = this.$store.state.customerDayOff;
    if (this.$store.state.customerPhone)
        this.customerPhone = this.$store.state.customerPhone;
  },
  computed: {
    cart_total(){
       var $this = this;
       let total = 0;
       Object.keys($this.$store.state.items).forEach(function(elem){
            Object.entries($this.$store.state.items[elem]).forEach(function(val,k){
                let item = Object.assign(val[1],{});
                if ('total' in item)
                  total += item.total;
            });
       });
        return total;
    }
  },
  methods: {
    checkForm() {
      if (this.customer == '') {
        this.customerNameErrorSlot = true;
        return false;
      } else this.customerNameErrorSlot = false;
      return true;
    },
    checkCustomerName(e) {
      if (this.customerNameErrorSlot && e != '')
        this.customerNameErrorSlot = false;
    },
    sendMessage(e) {
      e.preventDefault();
      let items = [];
      let currency = this.$store.state.settings.currency;
      let tax = this.$store.state.settings.tax;
      let swap = this.$store.state.items;
      if (this.checkForm()) {
        Object.keys(swap).forEach(function(elem){
            Object.entries(swap[elem]).forEach(function(val,k){
                let item = Object.assign(val[1],{});
                if ('cart_qty' in item)
                  items.push(item);
            });
        });
         let data = {
            task: "ADD_ORDER",
            customer : this.customer, 
            customerDayOff : this.customerDayOff,
            customerPhone : this.customerPhone,
            cart_total : Intl.NumberFormat('it-IT', {style: 'currency', currency: currency}).format(this.cart_total),
            cart_total_taxed : Intl.NumberFormat('it-IT', {style: 'currency', currency: currency}).format(this.cart_total * tax),
            items : items
         };
         this.axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
         this.axios.post('API/orders/', JSON.stringify(data))
         .then(resp => {
            
         })
         .catch(error => {
            console.log(error);
            this.status = 'error';
         });
        let message = [
          '*MALART -- Nuovo ordine*',
          `Cliente: ${this.customer}`,
          `Telefono: ${this.customerPhone}`,
          'Prodotti:',
          Object.values(items)
            .map(item => {
              return [item.cart_qty, 'x *', item.descr,'*'].join('');
            })
            .join(`%0a`),
        ].join(`%0a`);
        this.$store.commit('setCustomer', this.customer);
        this.$store.commit('setCustomerPhone', this.customerPhone);
        this.$store.commit('setCustomerDayOff', this.customerDayOff);
        this.$store.commit('clearCart');
        this.order_sent = true;
        window.open(`https://api.whatsapp.com/send?phone=39${this.phoneNumber}&text=${message}`);
        this.$router.push('/');
      } 
    }
  }
};
</script>

<style lang="scss">
@use 'carbon-components/scss/globals/scss/spacing' as s;

.confirmOrder {
  overflow: hidden;

  .cv-grid .cv-row {
    padding: s.$spacing-03 0;
  }
  .cv-button-set {
    position: absolute;
    bottom: 0;
    width: 100%;

    .cv-button {
      max-width: 100%;

      &:last-child {
        margin-top: s.$spacing-01;
      }
    }
  }
  p {
    padding: s.$spacing-05 0 s.$spacing-13 0;
  }
}
</style>
